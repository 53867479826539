<template>
    
    <Section :options="sectionOptions">

            <form id="profile-form" name="update-password-form" class="update-password-form mb-0" action="#" method="post" @submit="onFormSubmit">

                <validation-observer v-slot="{ invalid }" slim>
                    <div class="row">

                        <TextField 
                            v-bind:value.sync="form.email" 
                            id="email" 
                            :label="labels.email" 
                            :placeholder="labels.email_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="email"
                            :disabled="!updateEmailAllowed"
                            required 
                        />

                        <TextField 
                            v-bind:value.sync="form.currentPassword" 
                            :label="labels.currentPassword" 
                            :placeholder="labels.currentPassword_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            required
                        />

                        <TextField 
                            v-bind:value.sync="form.password" 
                            :label="labels.password" 
                            :placeholder="labels.password_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            validationMode="aggressive"
                            :validationRules="passwordValidationRules"
                        />

                        <TextField 
                            v-bind:value.sync="form.confirmPassword" 
                            :label="labels.confirmPassword" 
                            :placeholder="labels.confirmPassword_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            validationMode="aggressive"
                            :validationRules="confirmPasswordValidationRules"
                        />

                        <div class="col-12 form-group">
                            <button :disabled="invalid" class="button button-3d button-black m-0 ladda-button" data-style="zoom-in" id="update-password-form-submit" name="update-password-form-submit" value="update-password" type="submit">{{labels.button}}</button>
                        </div>
                    </div>
                </validation-observer>

            </form>

    </Section>

</template>

<style>

.profile-form {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { extend } from "@fwk-node-modules/vee-validate";
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';

/** @cmsOptions */
export interface UpdatePasswordOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions,
    updateEmailAllowed?:boolean
}

/** @cmsLabels */
export interface UpdatePasswordLabels {
    
}

/** @cmsSlots */
export interface UpdatePasswordSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<UpdatePasswordOptions>,
        labels: {
          type: Object as PropType<UpdatePasswordLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<UpdatePasswordSlots>
    },
    components: {
        Section,
        TextField
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const user = $store.getters['authentication/' + authenticationTypes.getters.GET_USER];

        const form = {
            email: user.email,
            currentPassword: "",
            password:"",
            confirmPassword:""
        }

        var laddaSubmit:Ladda.LaddaButton|null = null;

        const labels = {
            email: app.$t('authentication.register.form.email'),
            email_placeholder: app.$t('authentication.register.form.email_placeholder'),
            currentPassword: app.$t('authentication.update-password.form.currentPassword'),
            currentPassword_placeholder: app.$t('authentication.update-password.form.currentPassword_placeholder'),
            password: app.$t('authentication.register.form.password'),
            password_placeholder: app.$t('authentication.register.form.password_placeholder'),
            confirmPassword: app.$t('authentication.register.form.confirmPassword'),
            confirmPassword_placeholder: app.$t('authentication.register.form.confirmPassword_placeholder'),
            button: app.$t('authentication.update-password.button')
        }
        

        const computedOptions:UpdatePasswordOptions = {
            sectionOptions : {
                
            },
            updateEmailAllowed:false,
            ...props.options
        };

        extend('password',{
            validate(password:string, params:any):Promise<boolean|string> {

            if(password != "" && password.length < 5) {
                return Promise.resolve(app.$t("authentication.error.password-incorrect") as string);
            }
            return Promise.resolve(true);
            }
        });

        extend('confirmPassword',{
            validate(confirmPassword:string, params:any):Promise<boolean|string> {

            if(form.password != "" && confirmPassword != "" && form.password != confirmPassword) {
                return Promise.resolve(app.$t("authentication.error.confirmPassword-different") as string);
            }
            return Promise.resolve(true);
            }
        });

        const passwordValidationRules = {
            "required" : true,
            "password" : true
        }

        const confirmPasswordValidationRules = {
            "required" : true,
            "confirmPassword" : true
        }

        const onFormSubmit = (evt:Event) => {
            evt.preventDefault();

            var options:api.ApiVueOptions =  {
                app
            }

            var input = {
                "userID" : user._id,
                ...form
            }

            laddaSubmit!.start();
            
            api.postAPI('/api/auth/user/update', input, options).then((response:any) => {

                if(response.updated) {
                    
                }

                laddaSubmit!.stop();
            });
        }

        onMounted(() => {
            var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-password-form] button.ladda-button' );
            laddaSubmit = Ladda.create(button!);
        })

        onBeforeUnmount(() => {
        })

        return {
            sectionOptions:computedOptions.sectionOptions,
            form,
            labels,
            updateEmailAllowed:computedOptions.updateEmailAllowed,
            passwordValidationRules,
            confirmPasswordValidationRules,
            onFormSubmit
        }

    }
})
</script>